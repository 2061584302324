import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'gatsby';
import PostCardTag from './PostCardTag';

const PostWrapper = styled.div`
  margin: 0 0 60px;
  .card {
    &__title {
      font-size: 2em;
      margin: 0 0 0.5em;
      line-height: 1.2;
      font-family: var(--font-heading);

      a {
        border-bottom: 0.18em solid var(--black);
        padding-bottom: 0.15em;
        line-height: 1.65em;
        color: var(--black);
        text-decoration: none;
      }
    }

    &__tags {
      display: flex;
    }

  }
`;

const PostCard = ({ post }) => {
  const tags = post.frontmatter.tags;
  return (
    <PostWrapper>
      <div className="card">
        <h2 className="card__title">
          <Link to={`/blog/${post.fields.slug}`}>{post.frontmatter.title}</Link>
        </h2>
        <h3>{post.frontmatter.date}</h3>
        <p className="card__tags">
          {tags.map((tag) => ( 
              <PostCardTag tagName={tag} />
  ))}
        </p>
        <p>{post.excerpt}</p>
      </div>
    </PostWrapper>
  );
};

export default PostCard;
