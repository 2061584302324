import React from 'react';
import { styled } from 'linaria/react';
import useBlogData from '../static_queries/useBlogData';
import PostCard from './BlogPostCard/PostCard';

const BlogListDiv = styled.div`
`;

export default function BlogList({ data }) {
  const blogData = data || useBlogData();
  function renderBlogData() {
    return (
      <BlogListDiv>
        {blogData
          .filter((blog) => blog.node.frontmatter.title !== '')
          .map((blog) => (
            <PostCard post={blog.node} />
          ))}
      </BlogListDiv>
    );
  }
  return (
    <section>
      <ul className="list">{renderBlogData()}</ul>
    </section>
  );
}
