import React from 'react';
import { Link } from 'gatsby';
import { styled } from 'linaria/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TagWrapper = styled.span`
  .tag {
    transition: all 0.2s linear;
    opacity: 0.7;
    color: var(--green);
    margin-right: 1rem;
    border: 1px solid var(--green);
    padding: 4px 8px;
    border-radius: 14px;
    // font-weight: 700;
    border-radius: 12px;
    // background: #FDFFFC;
    box-shadow:  3px 3px 6px #efefef, 
                -6px -6px 12px #ffffff;
    
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--green);
      color: var(--white);
    }
    &:nth-of-type(1) {
      opacity: 1;
    }
    &:nth-of-type(2) {
      opacity: 0.9;
    }

    svg {
        margin-right: 0.4rem;
    }
  }
}
`;

interface Props {
  tagName: string;
}

const KNOWN_ICONS_MAP = {
  python: 'python',
  vue: 'vuejs',
  javascript: 'js',
};

const PostCardTag = (props: Props) => {
  const { tagName } = props;
  const url = `/blog/tag/${tagName}`;
  const withIcon = Object.keys(KNOWN_ICONS_MAP).includes(tagName);
  let iconName = '';
  if (withIcon) {
    // @ts-ignore
    iconName = KNOWN_ICONS_MAP[tagName];
  }
  return (
    <TagWrapper>
      <Link to={url} key={tagName} className="tag">
        {withIcon && <FontAwesomeIcon icon={['fab', iconName]} />}
        {tagName}
      </Link>
    </TagWrapper>
  );
};

export default PostCardTag;
